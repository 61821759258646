import WebServiceRequest from "../Api/WebServiceRequest";

class CreateNewIndexSlider extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("IndexSlider/Create");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class DeleteASliderIndex extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("IndexSlider/DeleteBy");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class UpdateASliderIndex extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("IndexSlider/Update");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}
export { CreateNewIndexSlider, DeleteASliderIndex, UpdateASliderIndex };
