import WebServiceRequest from "../Api/WebServiceRequest";

class CreateFile extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Media/CreateFile");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetLibraryFiles extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Media/GetLibraryFiles");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class DeleteAMedia extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Media/DeleteByPictureUrl");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

export { CreateFile, GetLibraryFiles, DeleteAMedia };
