import WebServiceRequest from "../Api/WebServiceRequest";

class GetASingleSetting extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Setting/GetBy");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class UpdateASetting extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Setting/Update");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

export { GetASingleSetting, UpdateASetting };
