<template>
  <b-modal
    id="details-modal"
    centered
    size="lg"
    ok-variant="success"
    title="ویرایش"
    ok-title="ویرایش"
    cancelTitle="انصراف"
    @ok="sendCreateFileRequest"
  >
    <b-container fluid v-if="sliderDetails">
      <b-form-row>
        <b-col cols="12">
          <!-- <b-form @submit.prevent>
              <b-form-group label="لینک صفحه" label-for="link">
                <b-form-input v-model="newAwardDetails.link"></b-form-input>
              </b-form-group>
            </b-form> -->
          <b-form @submit.prevent v-if="!isSvg">
            <b-form-group label="عکس اسلاید" label-for="imagePicker">
              <b-form-file
                id="imagePicker"
                ref="refInputEl"
                accept=".jpg, .png, .gif"
                placeholder="انتخاب عکس"
                @input="makeBase64Pic"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12" class="mb-2">
          <div v-if="!isSvg">
            <img
              v-if="mediaType == 'image'"
              :src="handleImageUrl(sliderDetails)"
              alt=""
              class="w-50 h-auto"
            />
            <video
              v-else
              :src="handleImageUrl(sliderDetails)"
              class="w-50 h-auto"
              controls
            ></video>
          </div>
          <div v-else>
            <!-- Here -->
            <b-row>
              <b-col
                v-for="(icon, index) in iconsList"
                :key="index"
                cols="4"
                class="d-flex align-items-center justify-content-center h-100 cursor-pointer"
                style="gap: 3rem"
                @click="setTempIcon(icon)"
              >
                <span
                  :class="
                    sliderDetails.imageUrl == icon.componentName
                      ? 'selected-svg'
                      : ''
                  "
                  v-html="icon.svg"
                ></span>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group label="عنوان اسلاید" label-for="title">
              <b-form-input
                id="title"
                placeholder="کارگزار تمام خدمات بازار سرمایه"
                v-model="sliderDetails.title"
              ></b-form-input>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group label="توضیحات اسلاید" label-for="description">
              <b-form-textarea
                id="description"
                placeholder="شرکت کارگزاری ستاره جنوب، کارگزار رسمی بورس و ..."
                v-model="sliderDetails.description"
              ></b-form-textarea>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12">
          <small>دکمه اسلاید</small>
          <hr />
          <b-row>
            <b-col cols="6">
              <b-form @submit.prevent>
                <b-form-group label="متن دکمه" label-for="linkName">
                  <b-form-input
                    v-model="sliderDetails.linkName"
                    id="linkName"
                    placeholder="ادامه مطلب"
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </b-col>
            <b-col cols="6">
              <b-form @submit.prevent>
                <b-form-group label="لینک دکمه" label-for="link">
                  <b-form-input
                    v-model="sliderDetails.link"
                    id="link"
                    placeholder="https://sjbourse.ir/صندوق-تمشک"
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <div
            class="w-100 d-flex align-items-center justify-content-center my-2"
          >
            <b-img
              v-if="imagePreview"
              :src="imagePreview"
              height="auto"
              width="400"
              class="rounded"
            />
          </div>
        </b-col>
      </b-form-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          v-b-modal.media-delete-modal
          variant="danger"
          size="sm"
          class="my-1"
        >
          حذف
        </b-button>
      </div>
      <b-modal
        id="media-delete-modal"
        title="حذف "
        ok-variant="danger"
        ok-title="بله"
        cancel-title="بستن"
        centered
        @ok="sendDeleteRequest"
      >
        <span>آیا از حذف اطمینان دارید؟</span>
      </b-modal>
    </b-container>
  </b-modal>
  <!-- <b-modal
    id="details-modal"
    size="lg"
    title="جزئیات اسلایدر"
    ok-title="بستن"
    centered
    ok-only
  >
    <template v-if="tempImage">
      <b-container fluid>
        <b-form-row>
          <b-col cols="6" v-if="!isSvg">
            <img
              v-if="mediaType == 'image'"
              :src="handleImageUrl(tempImage)"
              alt=""
              class="w-100 h-auto"
            />
            <video
              v-else
              :src="handleImageUrl(tempImage)"
              class="w-100 h-auto"
              controls
            ></video>
          </b-col>
          <b-col cols="6" v-else>
            <span v-html="tempImage.pictureUrl"></span>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="mediaType == 'image' ? 'آدرس عکس' : 'آدرس ویدیو'"
            >
              <b-form-input
                disabled
                :value="handleImageUrl(tempImage)"
              ></b-form-input>
            </b-form-group>
            <div class="w-100 d-flex align-items-center justify-content-end">
              <b-button
                size="sm"
                variant="primary"
                class="mb-2"
                @click="copyUrl(handleImageUrl(tempImage))"
              >
                کپی کردن
              </b-button>
            </div>
            <b-form-group label="تاریخ آپلود" v-if="tempImage.createDate">
              <b-form-input
                disabled
                :value="
                  new Date(tempImage.createDate).toLocaleDateString('fa-IR')
                "
              ></b-form-input>
            </b-form-group>
            <div class="w-100 d-flex align-items-center justify-content-end">
              <b-button
                v-b-modal.media-delete-modal
                variant="danger"
                size="sm"
                class="my-1"
              >
                حذف اسلایدر
              </b-button>
            </div>
          </b-col>
        </b-form-row>
      </b-container>
      <b-modal
        id="media-delete-modal"
        title="حذف اسلایدر"
        ok-variant="danger"
        ok-title="بله"
        cancel-title="بستن"
        centered
        @ok="sendDeleteRequest"
      >
        <span>آیا از حذف اسلایدر اطمینان دارید؟</span>
      </b-modal>
    </template>
  </b-modal> -->
</template>

<script>
export default {
  emits: ["refetch", "loading", "loaded"],
  props: {
    sliderDetails: {
      type: Object,
      required: true,
    },
    tempImage: {
      type: Object,
    },
    isSvg: {
      type: Boolean,
      required: false,
      default: false,
    },
    mediaType: {
      // image, video
      type: String,
      required: false,
      default: "image",
    },
  },
  data() {
    return {
      fileDetails: {
        base64: null,
        objectId: 0,
        type: 10, // 10 is for library
        priority: null,
      },
      imagePreview: null,
      tempImage: null,
      iconsList: [
        {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 3rem; height: 3rem"> <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" /> </svg>',
          componentName: "BarChartIcon",
        },
        {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 3rem; height: 3rem"> <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" /> </svg>',
          componentName: "CashIcon",
        },
        {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 3rem; height: 3rem"> <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" /> </svg>',
          componentName: "PresentationChartIcon",
        },
        {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="43" height="43" > <path fill="none" d="M0 0H24V24H0z" /> <path d="M5 3v16h16v2H3V3h2zm15.293 3.293l1.414 1.414L16 13.414l-3-2.999-4.293 4.292-1.414-1.414L13 7.586l3 2.999 4.293-4.292z" fill="rgba(110,107,123,1)" /> </svg>',
          componentName: "LineChartIcon",
        },
        {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="43" height="43" > <path fill="none" d="M0 0h24v24H0z" /> <path d="M2 4h2v16H2V4zm4 0h1v16H6V4zm2 0h2v16H8V4zm3 0h2v16h-2V4zm3 0h2v16h-2V4zm3 0h1v16h-1V4zm2 0h3v16h-3V4z" fill="rgba(110,107,123,1)" /> </svg>',
          componentName: "BarcodeIcon",
        },
        {
          svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="43" height="43" > <path fill="none" d="M0 0h24v24H0z" /> <path d="M11 2.05V13h10.95c-.501 5.053-4.765 9-9.95 9-5.523 0-10-4.477-10-10 0-5.185 3.947-9.449 9-9.95zm2 0A10.003 10.003 0 0 1 21.95 11H13V2.05z" fill="rgba(110,107,123,1)" /> </svg>',
          componentName: "PieChartIcon",
        },
      ],
    };
  },
  methods: {
    async updateASlider() {
      try {
        this.$emit("loading");
        let _this = this;
        let updateASliderIndex = new UpdateASliderIndex(_this);
        updateASliderIndex.setRequestParamDataObj(_this.sliderDetails);
        await updateASliderIndex.fetch((response) => {
          if (response.isSuccess) {
            _this.$emit("refetch");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `اسلایدر با موفقیت ویرایش شد.`,
              },
            });
            _this.$bvModal.hide("details-modal");
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("loaded");
      }
    },
    setTempIcon(icon) {
      this.sliderDetails.imageUrl = icon.componentName;
    },
    async sendCreateFileRequest() {
      if (this.fileDetails.base64) {
        try {
          this.$emit("loading");

          this.isLoading = true;
          let _this = this;
          let createFile = new CreateFile(_this);
          createFile.setRequestParamDataObj(_this.fileDetails);
          await createFile.fetch((response) => {
            if (response.isSuccess) {
              _this.sliderDetails.imageUrl = response.data.imageName;
              _this.updateASlider();
              _this.imagePreview = null;
              _this.fileDetails = {
                base64: null,
                objectId: 0,
                type: 10, // 10 is for library
                priority: null,
              };
            }
          });
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;
        }
      } else {
        this.updateASlider();
        this.$emit("loaded");
      }
    },
    async makeBase64Pic() {
      const _this = this;
      let file = _this.$refs.refInputEl.files[0];
      _this.imagePreview = URL.createObjectURL(file);
      const result = await _this.readAsDataURL(file);
      _this.fileDetails.base64 = result;
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        };
        fr.readAsDataURL(file);
      });
    },
    async sendDeleteRequest() {
      try {
        this.$emit("loading");
        let _this = this;
        let deleteASlider = new DeleteASliderIndex(_this);
        deleteASlider.setRequestParam({
          id: _this.sliderDetails.indexSliderId,
        });
        await deleteASlider.fetch((response) => {
          if (response.isSuccess) {
            _this.$emit("refetch");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `اسلایدر با موفقیت حذف شد.`,
              },
            });
            _this.$bvModal.hide("details-modal");
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("loaded");
      }
    },
    copyUrl(imageUrl) {
      if (navigator) {
        navigator.clipboard.writeText(imageUrl);
        this.$toast({
          component: ToastificationContent,
          position: "bottom-center",
          props: {
            title: `عملیات موفق`,
            icon: "CheckIcon",
            variant: "success",
            text: `لینک با موفقیت کپی شد.`,
          },
        });
      }
    },
    handleImageUrl(image) {
      if (!this.isSvg) {
        return `${Helper.baseUrl}/Media/Gallery/Library/${image.imageUrl}`;
      } else {
        return image.imageUrl;
      }
    },
  },
  components: {
    BModal,
    BContainer,
    BRow,
    BFormTextarea,
    BForm,
    BFormRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormFile,
    BImg,
  },
};
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Helper from "@/libs/Helper";
import { DeleteASliderIndex, UpdateASliderIndex } from "@/libs/Api/IndexSlider";
import { CreateFile } from "@/libs/Api/Media";
import {
  BModal,
  BContainer,
  BFormRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BFormTextarea,
  BForm,
  BFormFile,
  BImg,
} from "bootstrap-vue";
</script>
